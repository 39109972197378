<template>
	<div>
		<navComponents />
		<div class="bg1">
			<div class="bg1-main container_box">
				<div class="bg1-text-box">
					<div class="p2">
						<p>构建安全可信的实时数据基础设施平台，为企业数智化转型提供助力</p>
					</div>
					<div class="btn2">
						<button class="btn2-1" @click="$router.push('/request_trial')">申请免费体验</button>
						<button class="btn2-2" @click="$router.push('/download')">申请技术白皮书</button>
					</div>
				</div>
				<div class="bg2">
					<img src="~@/assets/images/homegif.gif" alt="" />
				</div>
			</div>
		</div>
		<div class="bg2">
			<div class="top-bottom-text">
				<div class="smoke-box" style="opacity: 0"></div>
				<p class="p1">「现代数据集成满足实时流式传输」</p>
				<p class="p2">在多个智能数据管道中的企业源和目标之间持续流式传输数据，以实现实时决策和商业智能。</p>
				<div class="hengxian" v-show="!isMobile"></div>
			</div>
			<template v-if="!isMobile">
				<div class="center-center-img">
					<svg class="icon" aria-hidden="true">
						<use xlink:href="#icon-home-jiantou"></use>
					</svg>
				</div>
				<div class="right-left-text1" style="left: 200px; top: 230px">
					<div class="smoke-box" style="opacity: 0.8"></div>
					<p class="p1">捕获</p>
					<p class="p2">通过变更数据捕获 (CDC) 和其他来源实时获取企业数据库中的数据。</p>
					<p></p>
				</div>
				<div class="right-left-text2" style="left: 200px; bottom: 170px">
					<div class="smoke-box" style="opacity: 0.8"></div>
					<p class="p1">转换</p>
					<p class="p2">使用简单的基于 SQL 的转换过滤、转换、丰富和关联传输中的数据。</p>
				</div>
				<div class="left-right-text1" style="right: 200px; top: 270px">
					<div class="smoke-box" style="opacity: 0.8"></div>
					<p class="p1">质量及监控</p>
					<p class="p2">实时数据交付验证、监控和警报提供对您的智能数据管道的可见性。</p>
				</div>
				<div class="left-right-text2" style="right: 200px; bottom: 130px">
					<div class="smoke-box" style="opacity: 0.8"></div>
					<p class="p1">投递</p>
					<p class="p2">向所有业务用户和客户实时提供分析就绪数据。</p>
				</div>
			</template>
			<template v-else>
				<div class="container_box">
					<div class="right-left-text">
						<p class="p1">捕获</p>
						<p class="p2">通过变更数据捕获 (CDC) 和其他来源实时获取企业数据库中的数据。</p>
					</div>
					<div class="right-left-text">
						<p class="p1">转换</p>
						<p class="p2">使用简单的基于 SQL 的转换过滤、转换、丰富和关联传输中的数据。</p>
					</div>
					<div class="right-left-text">
						<p class="p1">质量及监控</p>
						<p class="p2">实时数据交付验证、监控和警报提供对您的智能数据管道的可见性。</p>
					</div>
					<div class="right-left-text">
						<p class="p1">投递</p>
						<p class="p2">向所有业务用户和客户实时提供分析就绪数据。</p>
					</div>
				</div>
			</template>
		</div>
		<div class="bg3">
			<div class="w">
				<p class="p1">连接一切</p>
				<div class="module-box">
					<div class="module1">
						<div class="module-title1">数据源</div>
						<swiper ref="mySwiper" v-show="isMobile" :options="swiperOptions">
							<swiper-slide v-for="(item, index) in originList" :key="index">
								<div class="smallBox" v-for="(v, i) in item" :key="i">
									<div class="smallIcon">
										<svg class="icon" aria-hidden="true">
											<use :xlink:href="'#icon-da' + v.iconName"></use>
										</svg>
									</div>
									<p class="smallText">{{ v.name }}</p>
								</div>
							</swiper-slide>
							<!-- 如果你需要分页器 -->
							<div class="swiper-pagination" slot="pagination"></div>
						</swiper>
						<div class="module-iconBox" v-show="!isMobile">
							<div class="smallBox" v-for="(v, i) in originList.flat()" :key="i">
								<div class="smallIcon">
									<svg class="icon" aria-hidden="true">
										<use :xlink:href="'#icon-da' + v.iconName"></use>
									</svg>
								</div>
								<p class="smallText">{{ v.name }}</p>
							</div>
						</div>
					</div>
					<div class="module2">
						<div class="module-title2" v-show="!isMobile">数据目的地</div>
						<swiper ref="mySwiper2" v-show="isMobile" :options="swiperOptions">
							<swiper-slide v-for="(item, index) in originList" :key="index">
								<div class="smallBox" v-for="(v, i) in item" :key="i">
									<div class="smallIcon">
										<svg class="icon" aria-hidden="true">
											<use :xlink:href="'#icon-da' + v.iconName"></use>
										</svg>
									</div>
									<p class="smallText">{{ v.name }}</p>
								</div>
							</swiper-slide>
							<!-- 如果你需要分页器 -->
							<div class="swiper-pagination" slot="pagination"></div>
						</swiper>
						<div class="module-iconBox" v-show="!isMobile">
							<div class="smallBox" v-for="(v, i) in originList.flat()" :key="i">
								<div class="smallIcon">
									<svg class="icon" aria-hidden="true">
										<use :xlink:href="'#icon-da' + v.iconName"></use>
									</svg>
								</div>
								<p class="smallText">{{ v.name }}</p>
							</div>
						</div>
						<div class="module-title2" v-show="isMobile">数据目的地</div>
					</div>
				</div>
				<div class="poa-center">
					<div class="poa-center-left">
						<svg class="icon" aria-hidden="true">
							<use xlink:href="#icon-logo2"></use>
						</svg>
					</div>
					<div class="poa-center-center">
						<p class="p1">Transform</p>
						<p class="p2">Join</p>
						<p class="p3">Enrich</p>
					</div>
					<div class="poa-center-right">
						<svg class="icon" aria-hidden="true">
							<use xlink:href="#icon-logo2"></use>
						</svg>
					</div>
					<div class="poa-top">
						<svg-icon icon-class="home-jiantou-lan" style="width: 100%; height: 100%"></svg-icon>
					</div>
					<div class="poa-bottom">
						<svg-icon icon-class="home-jiantou-huang" style="width: 100%; height: 100%"></svg-icon>
					</div>
				</div>
				<div class="starBtnBox" v-show="!isMobile">
					<button class="starBtn" @click="$router.push('/product')">了解更多</button>
				</div>
			</div>
		</div>
		<div class="bg4">
			<div class="w">
				<div class="bg4-title">
					<p>「应用场景」</p>
					<div></div>
				</div>
				<div class="bg4-6" v-show="!isMobile">
					<div class="bg4-smallBox" v-for="(item, index) in bg4List" :key="index">
						<div class="left-box">
							<svg-icon :icon-class="item.icon" style="width: 100%; height: 100%"></svg-icon>
						</div>
						<div class="right-box">
							<p class="p1">{{ item.title }}</p>
							<p class="p2">{{ item.content }}</p>
						</div>
					</div>
				</div>
				<div class="bg4-6" v-show="isMobile">
					<div
						class="bg4-smallBox"
						v-for="(item, index) in bg4List"
						:key="index"
						@click="item.openDetails = !item.openDetails"
						:style="{ height: item.openDetails ? '208px' : '84px' }"
					>
						<div class="left-box">
							<div class="flex">
								<svg-icon :icon-class="item.icon" style="width: 33px; height: 33px"></svg-icon>
								<p class="p1">{{ item.title }}</p>
							</div>

							<span class="ico">
								<i class="icon iconfont icon-youjiantou" :style="{ transform: item.openDetails ? 'rotate(90deg)' : 'rotate(0deg)' }"></i>
							</span>
						</div>
						<div class="right-box" :style="{ flex: item.openDetails ? '1' : '0' }">
							<p class="p2">{{ item.content }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bg5">
			<p class="p1">准备好将您的数据付诸行动了吗</p>
			<p class="p2">通过StaRiver RDP团队成员个性化演示了解StaRiver RDP的实际应用，或立即开始试用。</p>
			<div class="btn2">
				<button class="btn2-2" @click="$router.push('/request_trial')">申请免费体验</button>
			</div>
		</div>
		<CopyrightComponents />
	</div>
</template>

<script>
//页面引入vue-awesome-swiper 及其样式
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { getJobLogListsData } from "@/api/index.js";
import navComponents from "@/components/Nav/Nav.vue";
import CopyrightComponents from "@/components/Copyright/Copyright.vue";
export default {
	components: {
		navComponents,
		CopyrightComponents,
		swiper,
		swiperSlide,
	},
	props: {},
	data() {
		return {
			screenWidth: "",
			screenHeight: "",
			originList: [
				[
					{
						name: "oracle",
						iconName: "Oracle",
					},
					{
						name: "mysql",
						iconName: "MySQL",
					},
					{
						name: "sqlserver",
						iconName: "SQLServer",
					},
					{
						name: "postgresql",
						iconName: "PostgreSQL",
					},
					{
						name: "DM",
						iconName: "DM",
					},
					{
						name: "oceanbase",
						iconName: "oceanbase",
					},
					{
						name: "OpenGauss",
						iconName: "OpenGauss",
					},
					{
						name: "Tidb",
						iconName: "Tidb",
					},
				],
				[
					{
						name: "TDSQL",
						iconName: "TDSQL",
					},
					{
						name: "GaussDB",
						iconName: "GaussDB",
					},
					{
						name: "PolarDB MySQL",
						iconName: "PolarDBMySQL",
					},
					{
						name: "GoldenDB",
						iconName: "GoldenDB",
					},
					{
						name: "Greenplum",
						iconName: "Greenplum",
					},
					{
						name: "FTP",
						iconName: "FTP",
					},
					{
						name: "Restful",
						iconName: "Restful",
					},
					{
						name: "Kafka",
						iconName: "Kafka",
					},
				],
			],
			swiperOptions: {
				// 这里配置Swiper的参数
				// 例如：自动播放、分页等
				// autoplay: {
				// 	delay: 2500,
				// 	disableOnInteraction: false,
				// },
				pagination: {
					el: ".swiper-pagination",
					clickable: true,
				},
				// 其他配置...
				centeredSlides: true,
				slidesPerView: 1,
			},
			bg4List: [
				{
					icon: "home-bg4-1",
					title: "多源数据间异构",
					content:
						"利用StaRiver RDP，实现多种源端数据库、目标端数据库、大数据组件等数据异构的同步，自动完成不同数据库之间类型、数据、字符集的转换，满足复杂的数据异构场景。",
					openDetails: false,
				},
				{
					icon: "home-bg4-2",
					title: "企业上云",
					content: "跨云同步、数据备份和数据库系统平滑升级等，支持同构或异构数据库实时同步。",
					openDetails: false,
				},
				{
					icon: "home-bg4-3",
					title: "实时数仓",
					content: "将企业主数据及运营数据统一汇聚到开发平台，按照基础数据、主数据和汇总数据分层，为企业BI、报表等系统提供数据。",
					openDetails: false,
				},
				{
					icon: "home-bg4-4",
					title: "实时数据中台",
					content: "打通数据孤岛，对数据进行实时采集，治理及建模，构建企业的主数据系统，为企业的交互式业务提供完整全面的数据底座。",
					openDetails: false,
				},
				{
					icon: "home-bg4-5",
					title: "统一查询服务平台",
					content: "通过构建统一的数据服务平台，跨部门间可以快速获取业务所需的数据，并以API方式实现自助访问。",
					openDetails: false,
				},
				{
					icon: "home-bg4-6",
					title: "数据汇聚/分发",
					content: "跨部门/地域数据采集汇聚，跨部门/地域数据分发。",
					openDetails: false,
				},
			],
		};
	},
	computed: {
		isMobile() {
			return (
				navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				) || this.screenWidth < 900
			);
		},
	},
	watch: {
		screenWidth() {
			console.log(this.screenWidth); //浏览器窗口变化时，打印宽度。
		},
	},
	created() {
		// this.go();
		localStorage.setItem(
			"tk",
			'"eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImE3ZTJlOWZkLTNmY2ItNDM4Yy1iMzg4LWIxNGVhMjNhZjNmMCJ9.GrB2lNJKPMKM_8Ndki1ua-HWhkWXzUZ2JIjRdRtEDlps0ySdFbXq97fa1-Yfi2-ujj3N1OQqbVbB-rgAXm9UQA"'
		);
	},
	mounted() {
		this.screenWidth = document.body.clientWidth; //监听页面缩放
		this.screenHeight = document.body.clientHeight;
		window.onresize = () => {
			return (() => {
				this.screenWidth = document.body.clientWidth;
				this.screenHeight = document.body.clientHeight;
			})();
		};
		if (!this.isMobile) {
			window.addEventListener("scroll", this.handleScroll);
		} else {
			this.updateSwiper();
		}
	},

	methods: {
		updateSwiper() {
			const swiper = this.$refs.mySwiper.swiper;
			const swiper2 = this.$refs.mySwiper2.swiper;
			if (swiper) {
				swiper.on("slideChangeTransitionEnd", () => {
					// 这里编写你的回调函数
					const width = swiper.$wrapperEl[0].getBoundingClientRect().width;
					swiper.$wrapperEl[0].style.transform = `translate3d(-${swiper.activeIndex * width}px, 0px, 0px)`;
					swiper.update();
					swiper2.update();
				});
			}
			if (swiper2) {
				swiper2.on("slideChangeTransitionEnd", () => {
					// 这里编写你的回调函数
					const width = swiper2.$wrapperEl[0].getBoundingClientRect().width;
					swiper2.$wrapperEl[0].style.transform = `translate3d(-${swiper2.activeIndex * width}px, 0px, 0px)`;
					swiper.update();
					swiper2.update();
				});
			}
		},
		jump2Introduction() {
			this.$router.push({
				path: "/product",
			});
		},
		// 监听滚动条时间
		handleScroll() {
			var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
			console.log(scrollTop);
			let dom0 = document.querySelector(".top-bottom-text");
			let smokeDom = document.querySelectorAll(".smoke-box");
			let l2rdom1 = document.querySelector(".right-left-text1");
			let l2rdom2 = document.querySelector(".right-left-text2");
			let l2rdom3 = document.querySelector(".left-right-text1");
			let l2rdom4 = document.querySelector(".left-right-text2");
			if (!smokeDom || !l2rdom1 || !l2rdom2 || !l2rdom3 || !l2rdom4) return;
			if (scrollTop >= 0 && scrollTop < 200) {
				// l2rdom1.style.setProperty("left", "100px");
				// l2rdom2.style.setProperty("left", "100px");
				// l2rdom3.style.setProperty("right", "100px");
				// l2rdom4.style.setProperty("right", "100px");
				// smokeDom[0].style.setProperty("opacity", "1");
				// smokeDom[1].style.setProperty("opacity", "0.9");
				// smokeDom[2].style.setProperty("opacity", "0.9");
				// smokeDom[3].style.setProperty("opacity", "0.9");
				// smokeDom[4].style.setProperty("opacity", "0.9");
				// l2rdom1.style.setProperty('opacity', '0')
				// l2rdom2.style.setProperty('opacity', '0')
				// l2rdom3.style.setProperty('opacity', '0')
				// l2rdom4.style.setProperty('opacity', '0')
			} else if (scrollTop >= 200 && scrollTop < 300) {
				l2rdom1.style.setProperty("left", "200px");
				l2rdom2.style.setProperty("left", "200px");
				l2rdom3.style.setProperty("right", "200px");
				l2rdom4.style.setProperty("right", "200px");
				// smokeDom[0].style.setProperty("opacity", "0.8");
				smokeDom[1].style.setProperty("opacity", "0.8");
				smokeDom[2].style.setProperty("opacity", "0.8");
				smokeDom[3].style.setProperty("opacity", "0.8");
				smokeDom[4].style.setProperty("opacity", "0.8");
			} else if (scrollTop >= 300 && scrollTop < 400) {
				l2rdom1.style.setProperty("left", "300px");
				l2rdom2.style.setProperty("left", "300px");
				l2rdom3.style.setProperty("right", "300px");
				l2rdom4.style.setProperty("right", "300px");
				// smokeDom[0].style.setProperty("opacity", "0.6");
				smokeDom[1].style.setProperty("opacity", "0.6");
				smokeDom[2].style.setProperty("opacity", "0.6");
				smokeDom[3].style.setProperty("opacity", "0.6");
				smokeDom[4].style.setProperty("opacity", "0.6");
			} else if (scrollTop >= 400 && scrollTop < 500) {
				l2rdom1.style.setProperty("left", "400px");
				l2rdom2.style.setProperty("left", "400px");
				l2rdom3.style.setProperty("right", "400px");
				l2rdom4.style.setProperty("right", "400px");
				// smokeDom[0].style.setProperty("opacity", "0.4");
				smokeDom[1].style.setProperty("opacity", "0.4");
				smokeDom[2].style.setProperty("opacity", "0.4");
				smokeDom[3].style.setProperty("opacity", "0.4");
				smokeDom[4].style.setProperty("opacity", "0.4");
			} else if (scrollTop >= 500 && scrollTop < 600) {
				l2rdom1.style.setProperty("left", "500px");
				l2rdom2.style.setProperty("left", "500px");
				l2rdom3.style.setProperty("right", "500px");
				l2rdom4.style.setProperty("right", "500px");
				// smokeDom[0].style.setProperty("opacity", "0.2");
				smokeDom[1].style.setProperty("opacity", "0.2");
				smokeDom[2].style.setProperty("opacity", "0.2");
				smokeDom[3].style.setProperty("opacity", "0.2");
				smokeDom[4].style.setProperty("opacity", "0.2");
			} else {
				l2rdom1.style.setProperty("left", "500px");
				l2rdom2.style.setProperty("left", "500px");
				l2rdom3.style.setProperty("right", "500px");
				l2rdom4.style.setProperty("right", "500px");
				// smokeDom[0].style.setProperty("opacity", "0");
				smokeDom[1].style.setProperty("opacity", "0");
				smokeDom[2].style.setProperty("opacity", "0");
				smokeDom[3].style.setProperty("opacity", "0");
				smokeDom[4].style.setProperty("opacity", "0");
			}
		},

		changeNav(index) {
			console.log(this.$route.path, index);
			this.$router.push(index);
		},
		go() {
			getJobLogListsData({
				taskId: "8bca688d782b40b6bdcd2239bf2dbeb3",
				logLevelError: true,
				logLevelWarning: true,
				offset: 1,
				pageSize: 10,
			}).then(res => {
				console.log(res);
			});
		},
	},
};
</script>

<style scoped lang="scss">
.bg1 {
	background: url("~@/assets/images/home-bg1.png") no-repeat center;
	background-size: 100% 100%;
	font-size: 50px;
	font-weight: 700;
	color: #fff;
	margin: 0 auto;
	overflow: hidden;
	.bg1-main {
		margin: 24px auto;
		height: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-top: 50px;

		.bg2 {
			flex: 1;
			height: auto;
			margin: 0 !important;
			background: url("~@/assets/images/home-gif-shadow.png") no-repeat center;
			background-size: 100% 100%;
			background-color: transparent;
			img {
				width: 100%;
				margin: 0 !important;
			}
		}

		.bg1-text-box {
			flex: 1;
			display: flex;
			flex-direction: column;

			.p1 {
				font-size: 18px;
				margin-bottom: 30px;
			}

			.p2 {
				font-size: 30px;
				line-height: 60px;
				font-family: "FZXiaoBiaoSong-B05S";
				flex: 0.8;
				display: flex;
				align-items: center;
			}

			.p3 {
				font-size: 16px;
				margin-bottom: 55px;
			}

			.btn2 {
				height: 41px;
				width: 100%;

				button {
					height: 41px;
					width: 140px;
					border-radius: 23px;
					border: 0;
					margin-top: -20px;
					cursor: pointer;
					font-size: 14px;
					font-family: "Microsoft YaHei";
					font-weight: 700;
					line-height: 41px;
				}

				.btn2-1 {
					background-color: #f4c307;
					margin-right: 25px;
					color: #fff;
					transition: 0.5s all;
					&:hover {
						transform: scale(1.1);
					}
					a {
						text-decoration: none;
						color: #fff;
						font-size: 14px;
					}
				}

				.btn2-2 {
					background-color: #54cb9a;
					color: #fff;
					transition: 0.5s all;
					a {
						text-decoration: none;
						color: #fff;
						font-size: 14px;
					}
					&:hover {
						transform: scale(1.1);
					}
				}

				.disabled-btn {
					background: #cfcfcf;
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					line-height: 25.6px;
					cursor: not-allowed;
					a {
						color: #000000;
						cursor: not-allowed;
					}
					&:hover {
						transform: scale(1);
					}
				}
			}
		}
	}
}

.bg2 {
	height: 692px;
	margin: 0 auto;
	background-color: #000e24;
	position: relative;

	// 最终位置
	.top-bottom-text {
		position: relative;
		height: 76px;
		top: 69px;
		left: 50%;
		text-align: center;
		transform: translateX(-50%);
		.smoke-box {
			background-color: #000e24;
			position: absolute;
			z-index: 8;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
		.p1,
		.p2 {
			color: #fff;
		}

		.p1 {
			font-size: 20px;
			font-family: "Microsoft YaHei";
			font-weight: bold;
			margin-bottom: 10px;
		}

		.p2 {
			font-size: 16px;
			font-family: "Microsoft YaHei";
			font-weight: 400;
			margin-bottom: 15px;
		}

		.hengxian {
			width: 60px;
			height: 2px;
			background: #ffffff;
			margin: 0 auto;
		}
	}

	.center-center-img {
		background-color: #000e24;
		position: absolute;
		width: 89px;
		height: 450px;
		top: 60%;
		left: 42%;
		transform: translate(-50%, -50%);
		svg {
			width: 460%;
			height: 100%;
		}
	}

	.right-left-text1,
	.right-left-text2,
	.left-right-text1,
	.left-right-text2 {
		position: absolute;
		width: 285px;
		height: 100px;
		transition: all 1s;

		.smoke-box {
			background-color: #000e24;
			position: absolute;
			z-index: 8;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		.p1 {
			font-size: 24px;
			font-family: "Microsoft YaHei";
			font-weight: bold;
			color: #0092fb;
			margin-bottom: 10px;
		}

		.p2 {
			font-size: 16px;
			font-family: "Microsoft YaHei";
			font-weight: 400;
			color: #ffffff;
			line-height: 25.6px;
		}
	}
}

.bg3 {
	// height: 578px;
	background-color: #f8f8f8;
	margin: 0 auto;
	padding-bottom: 50px;

	.w {
		width: 1300px;
		margin: 0 auto;
		border: 1px solid transparent;
		position: relative;

		.p1 {
			width: 100%;
			text-align: center;
			font-size: 40px;
			font-family: Microsoft YaHei;
			font-weight: 500;
			color: #000000;
			margin-top: 60px;
		}

		.module-box {
			display: flex;
			justify-content: space-between;
			// height: 300px;
			margin-top: 55px;
			margin-bottom: 30px;

			.module1,
			.module2 {
				width: 505px;
				// height: 264px;
				background-color: #ffffff;
				border-radius: 6px;
				position: relative;
				padding: 0 0 20px;

				.module-iconBox {
					width: 100%;
					height: 100%;
					padding-left: 40px;
					padding-top: 60px;
					padding-right: 40px;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;

					.smallBox {
						width: 90px;
						height: 90px;
						border: 1px solid #d0d0d0;
						margin-bottom: 20px;
						display: flex;
						justify-content: center;
						flex-direction: column;
						align-items: center;

						.smallIcon {
							width: 45px;
							height: 30px;
							// margin: 13px auto 0;

							svg {
								width: 100%;
								height: 100%;
							}
						}

						.smallText {
							width: 100%;
							height: 14px;
							text-align: center;
							margin-top: 8px;
							font-size: 12px;
						}
					}
				}

				.module-title1,
				.module-title2 {
					width: 420px;
					height: 50px;
					border-radius: 6px;
					font-size: 18px;
					font-family: "Microsoft YaHei";
					font-weight: 700;
					color: #ffffff;
					line-height: 50px;
					text-align: center;
					position: absolute;
					left: 50%;
					top: -25px;
					transform: translateX(-50%);
				}

				.module-title1 {
					background-color: #0092fb;
				}

				.module-title2 {
					background-color: #f4c307;
				}
			}
		}

		.poa-center {
			z-index: 8;
			position: absolute;
			width: 340px;
			height: 238px;
			left: calc(50% - 340px / 2);
			top: 41%;
			display: flex;
			justify-content: space-between;

			// position: relative;
			.poa-center-left,
			.poa-center-right {
				width: 104px;
				height: 70px;
				border-radius: 5px;
				margin-top: 84px;
				padding: 20px;
				svg {
					font-size: 30px;
					width: 150%;
					height: 150%;
				}
			}

			.poa-center-left {
				background: #0092fb;
			}

			.poa-center-right {
				background: #f4c307;
			}

			.poa-center-center {
				flex: 1;
				height: 110px;
				z-index: 8;

				.p1,
				.p2,
				.p3 {
					width: 100%;
					text-align: center;
					font-size: 14px;
					color: #000;
					font-family: "MicrosoftYaHei";
				}

				.p1,
				.p2 {
					margin-bottom: 23px;
				}
			}

			.poa-top {
				position: absolute;
				width: 270px;
				height: 76px;
				top: 0;
				left: calc(50% - 270px / 2);
			}

			.poa-bottom {
				position: absolute;
				width: 270px;
				height: 76px;
				bottom: 0;
				left: calc(50% - 270px / 2);
			}
		}

		.starBtnBox {
			width: 140px;
			height: 41px;
			margin: 0 auto;

			.starBtn {
				background: #f4c307;
				border-radius: 23px;
				font-size: 14px;
				color: #000000;
				border: 0;
				width: 100%;
				height: 100%;
				cursor: pointer;
				transition: 0.5s all;
				&:hover {
					transform: scale(1.1);
				}
				a {
					text-decoration: none;
					color: #000;
					font-size: 14px;
				}
			}
			.disabled-btn {
				background: #cfcfcf;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				line-height: 25.6px;
				cursor: not-allowed;
				a {
					color: #000000;
					cursor: not-allowed;
				}
				&:hover {
					transform: scale(1);
				}
			}
		}
	}
}

.bg4 {
	height: 580px;
	background-color: #ffffff;
	margin: 0 auto;

	.w {
		width: 1300px;
		margin: 0 auto;

		.bg4-title {
			width: 300px;
			height: 50px;
			margin: 70px auto 0;

			p {
				height: 23px;
				text-align: center;
				font-size: 20px;
				font-weight: 700;
				color: #1b1b1b;
				font-family: "Microsoft YaHei";
			}

			div {
				width: 60px;
				margin: 23px auto;
				height: 2px;
				background-color: #000;
			}
		}

		.bg4-6 {
			margin-top: 40px;
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			text-align: justify;

			.bg4-smallBox {
				width: 413px;
				height: 220px;
				border-bottom: 30px;
				background-color: #f7f7f7;
				padding-left: 40px;
				padding-right: 30px;
				padding-top: 36px;
				display: flex;
				margin-bottom: 28px;

				.left-box {
					margin-right: 30px;
					width: 44px;
					height: 44px;
				}

				.right-box {
					flex: 1;

					.p1 {
						font-size: 16px;
						font-family: "Microsoft YaHei";
						color: #000;
						font-weight: 700;
						margin-bottom: 23px;
					}

					.p2 {
						font-size: 14px;
						font-family: "Microsoft YaHei";
						color: #000;
						font-weight: 500;
						line-height: 25.6px;
					}
				}
			}
		}
	}
}

.bg5 {
	height: 411px;
	margin: 0 auto;
	background: url("~@/assets/images/home-bg5.png") no-repeat center;
	background-size: 100% 100%;
	overflow: hidden;

	.p1 {
		font-size: 48px;
		color: #1b1b1b;
		text-align: center;
		margin-top: 66px;
		font-family: "MicrosoftYaHei";
	}

	.p2 {
		font-size: 18px;
		color: #000000;
		text-align: center;
		margin-top: 41px;
		font-family: "MicrosoftYaHei";
	}

	.btn2 {
		margin: 76px auto 0;
		height: 41px;
		// width: 305px;
		display: flex;
		justify-content: center;

		button {
			height: 41px;
			width: 140px;
			border-radius: 23px;
			border: 0;
			cursor: pointer;
			font-size: 14px;
			font-family: "Microsoft YaHei";
			font-weight: 700;
			line-height: 41px;
		}

		.btn2-1 {
			background-color: #f4c307;
			margin-right: 25px;
			color: #000;
			transition: 0.5s all;
			&:hover {
				transform: scale(1.1);
			}
			a {
				text-decoration: none;
				color: #000;
				font-size: 14px;
			}
		}

		.btn2-2 {
			background-color: #54cb9a;
			color: #fff;
			transition: 0.5s all;
			&:hover {
				transform: scale(1.1);
			}
			a {
				text-decoration: none;
				color: #fff;
				font-size: 14px;
			}
		}
		.disabled-btn {
			background: #cfcfcf;
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			line-height: 25.6px;
			cursor: not-allowed;
			a {
				color: #000000;
				cursor: not-allowed;
			}
			&:hover {
				transform: scale(1);
			}
		}
	}
}

@media (max-width: 900px) {
	.bg1 {
		.bg1-main {
			flex-direction: column-reverse;
			padding-top: 30px;
			.bg1-text-box {
				.p2 {
					opacity: 0.5;
					font-size: 18px;
					line-height: 30px;
					text-align: center;
				}
				.btn2 {
					height: auto;
					text-align: center;
					button {
						margin-top: 0;
					}
				}
			}
			.bg2 {
				padding: 0;
				background-size: 100% 92%;
			}
		}
	}
	.bg2 {
		height: auto;
		padding: 20px 0;
		.top-bottom-text {
			width: 92%;
			transform: none;
			top: 0;
			left: 0;
			margin: 0 auto;
			padding-top: 10px;
			height: auto;
		}
		.container_box {
			color: #fff;
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			.right-left-text {
				width: 44vw;
				height: 44vw;
				text-align: center;
				position: relative;
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 0 20px;

				&::after {
					content: "";
					position: absolute;
					width: 100%;
					height: 1px;
					background: linear-gradient(to left, #a2a6ab, #172437);
				}

				&:nth-child(1) {
					&::after {
						bottom: 0;
						left: 0;
					}
				}
				&:nth-child(2) {
					&::after {
						top: 0;
						left: -1px;
						transform-origin: 0;
						transform: rotate(90deg);
					}
				}
				&:nth-child(3) {
					&::after {
						top: 1px;
						left: 0;
						transform-origin: 100%;
						transform: rotate(-90deg);
					}
				}
				&:nth-child(4) {
					padding-bottom: 30px;
					&::after {
						top: -1px;
						left: 0;
						// transform-origin: 100%;
						transform: rotateY(180deg);
					}
				}

				.p1 {
					font-size: 18px;
					font-family: "Microsoft YaHei";
					font-weight: bold;
					color: #0092fb;
					margin-bottom: 10px;
				}

				.p2 {
					font-size: 14px;
					font-family: "Microsoft YaHei";
					font-weight: 400;
					color: #ffffff;
					line-height: 25.6px;
				}
			}
		}
	}
	.bg3 {
		padding-bottom: 20px;
		.w {
			width: 92%;
			.poa-center {
				flex-direction: column;
				justify-content: center;
				align-items: center;
				height: 166px;
				left: 0;
				top: 46%;
				width: 100%;
				.poa-center-left,
				.poa-center-right {
					margin-top: 0;
				}
				.poa-center-center {
					margin: 20px 0;
					.p1,
					.p2 {
						margin: 0;
						margin-bottom: 10px;
					}
				}
				.poa-top,
				.poa-bottom {
					transform: rotate(-90deg);
					width: 160px;
					height: auto;
				}

				.poa-top {
					left: calc(50% - 151.2px - 10px);
				}
				.poa-bottom {
					left: calc(50% + 5px);
					bottom: 14px;
				}
			}
			.module-box {
				flex-direction: column;
				margin: 0;
				padding-top: 36px;
				padding-bottom: 30px;

				.swiper-slide {
					width: 100% !important;
					display: flex;
					flex-wrap: wrap;
					justify-content: space-evenly;
					align-items: center;
					padding: 40px 10px;

					.smallBox {
						width: calc((100vw - 75px) / 4);
						height: calc((100vw - 75px) / 4);
						border: 1px solid #d0d0d0;
						display: flex;
						justify-content: center;
						flex-direction: column;
						align-items: center;
						margin-bottom: 5px;

						.smallIcon {
							width: 45px;
							height: 30px;
							// margin: 13px auto 0;

							svg {
								width: 100%;
								height: 100%;
							}
						}
					}

					.smallText {
						width: 100%;
						height: 14px;
						text-align: center;
						margin-top: 10px;
						font-size: 10px;
					}
				}
				.module2 {
					margin-top: 190px;
					width: 100%;
					padding-top: 10px;
					.module-title2 {
						width: 158px;
						height: 32px;
						line-height: 32px;
						top: calc(100% - 16px);
					}
				}
				.module1 {
					width: 100%;
					padding-bottom: 40px;
					.module-title1 {
						width: 158px;
						height: 32px;
						line-height: 32px;
						top: -18px;
					}
				}
			}
			.p1 {
				margin-top: 30px;
				font-size: 32px;
			}
		}
	}
	.bg4 {
		height: auto;
		padding-bottom: 20px;
		.w {
			width: 92%;
			.bg4-title {
				margin-top: 20px;
				div {
					margin: 15px auto;
				}
			}
			.bg4-6 {
				margin-top: 10px;
				.bg4-smallBox {
					flex-direction: column;
					height: auto;
					padding: 20px;
					transition: all 240ms;
					box-shadow: 0px 2px 4px 2px rgba(98, 114, 146, 0.1);
					border-radius: 5px 5px 5px 5px;
					margin-bottom: 15px;
					width: 100%;
					.left-box {
						width: auto;
						display: flex;
						align-items: center;
						justify-content: space-between;
						margin-right: 0;
						.flex {
							display: flex;
							align-items: center;
						}
						.p1 {
							font-weight: bold;
							font-size: 16px;
							margin-left: 20px;
						}
						.ico {
							.icon {
								font-size: 26px;
								color: #297aff;
								display: inline-block;
								transition: all 240ms;
							}
						}
					}
					.right-box {
						overflow: hidden;
						transition: all 240ms;
						padding-left: 53px;
						.p2 {
							transition: all 240ms;
						}
					}
				}
			}
		}
	}
	.bg5 {
		height: 100%;
		padding: 25px;
		.p1 {
			font-size: 22px;
			margin-top: 0;
		}
		.p2 {
			margin-top: 18px;
			color: #595e67;
			font-size: 14px;
			text-align: center;
		}
		.btn2 {
			margin-top: 15px;
		}
	}
}
</style>
