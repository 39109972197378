<template>
	<div class="big-box">
		<div class="into-body-box-title">
			<!-- w版心 -->
			<div class="w container_box">
				<div class="logo">
					<svg class="icon" aria-hidden="true">
						<use xlink:href="#icon-logo2"></use>
					</svg>
					<!-- <svg xmlns="http://www.w3.org/2000/svg" width="91" height="39.719" viewBox="0 0 91 39.719">
            <path id="形状_42" :fill="ac" data-name="形状 42" class="cls-1" d="M298.4,47.469l17.273-1.346a41.766,41.766,0,0,0-48.206-13.231,37.019,37.019,0,0,1,13.191,1.9A36.23,36.23,0,0,1,298.4,47.469Zm-23.687-4.993,10.693-4.17a33.257,33.257,0,0,0-17.019-4.229c-0.709.035-1.415,0.091-2.118,0.164ZM242.593,29.255L225.319,30.6a42.354,42.354,0,0,0,19.763,13.973,42.3,42.3,0,0,0,28.443-.742,37.013,37.013,0,0,1-13.19-1.9A36.23,36.23,0,0,1,242.593,29.255Zm23.686,4.993-10.692,4.17a33.252,33.252,0,0,0,17.019,4.229c0.709-.034,1.415-0.091,2.118-0.163Zm-30.485,30.49q0,4.239-4.57,4.238h-5.918V67.057h5.918a3.769,3.769,0,0,0,1.97-.4,2.308,2.308,0,0,0,.709-1.919q0-1.919-2.443-1.918h-1.977a5.228,5.228,0,0,1-3.074-.88,3.165,3.165,0,0,1-1.419-2.8,3.224,3.224,0,0,1,1.576-2.959,5.269,5.269,0,0,1,2.68-.64h6v1.919h-6q-2.365,0-2.365,1.679a1.524,1.524,0,0,0,.631,1.279,3.487,3.487,0,0,0,1.971.48h1.977a4.949,4.949,0,0,1,2.837.8A3.467,3.467,0,0,1,235.794,64.737ZM248,57.462h-4.525V68.975h-1.812V57.462h-4.517V55.542H248v1.919Zm11.675,11.514h2.049l-3.868-11.434a4.172,4.172,0,0,0-.946-1.519,2.334,2.334,0,0,0-1.576-.48h-1.419L249.38,68.975h2.029l3.768-11.594h0.158a0.733,0.733,0,0,1,.63.56Zm14.932,0h-2.049l-3.305-5.517h-4.34v5.517H263.1V55.542h6.073a4.952,4.952,0,0,1,2.758.72,3.958,3.958,0,0,1-.788,6.876Zm-2.832-9.435q0-2.077-2.6-2.078h-4.261v4.158h4.261Q271.776,61.619,271.776,59.54ZM316,68.975h-2.049l-3.305-5.517h-4.339v5.517h-1.813V55.542h6.074a4.952,4.952,0,0,1,2.758.72,3.958,3.958,0,0,1-.788,6.876Zm-2.832-9.435q0-2.077-2.6-2.078h-4.261v4.158h4.261Q313.165,61.619,313.166,59.54Zm-35.1,9.435h-1.892V55.542h1.892V68.975Zm13.878-13.433-5.158,13.433h-1.97l-5.194-13.433h2.049L285.842,66.5,289.9,55.542h2.048ZM302.99,68.975h-4.366q-2.916,0-4.1-1.279-1.183-1.359-1.182-4.477V55.542h9.646v1.919h-7.834V61.14h6.406v1.839h-6.406v0.24a4.367,4.367,0,0,0,.788,3.038,3.511,3.511,0,0,0,2.68.8h4.366v1.919Z" transform="translate(-225 -29.25)"/>
          </svg> -->
				</div>
				<!-- <div class="phone-num">
					<div class="phone-iconbox">
						<i class="iconfont icon-dianhua"></i>
					</div>
					<p>400-000-3636</p>
				</div> -->
				<div class="title-nav" id="titleNav" :style="{ height: navshow && isMobile ? '306px' : !navshow && isMobile ? '0' : '' }">
					<el-menu :default-active="$route.path" class="el-menu-demo" mode="horizontal" router>
						<el-menu-item :index="item.index" v-for="(item, index) in list" :key="index">
							<div>{{ item.title }}</div>
							<span v-show="isMobile">
								<i></i>
							</span>
						</el-menu-item>

						<!-- <el-menu-item index="4"><a href="https://www.ele.me" target="_blank">订单管理</a></el-menu-item> -->
					</el-menu>
				</div>
				<div class="ico" @click="changeList">
					<i class="icon iconfont icon-daohang" v-if="!navshow"></i>
					<i class="icon iconfont icon-015" v-else></i>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getJobLogListsData } from "@/api/index.js";
export default {
	props: {},
	data() {
		return {
			logo2: require("@/assets/icons/svg/logo2.svg"),
			ac: "#ccc",
			navshow: false,
			list: [
				{
					index: "/",
					title: "首页",
				},
				{
					index: "/product",
					title: "产品介绍",
				},
				{
					index: "/resolution",
					title: "解决方案",
				},
				{
					index: "/cases",
					title: "成功案例",
				},
				{
					index: "/about_us",
					title: "关于我们",
				},
				{
					index: "/join_us",
					title: "招贤纳士",
				},
			],
			screenWidth: "",
			screenHeight: "",
		};
	},
	computed: {
		isMobile() {
			return (
				navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				) || this.screenWidth < 900
			);
		},
	},
	watch: {},
	created() {
		// this.go();
		localStorage.setItem(
			"tk",
			'"eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImE3ZTJlOWZkLTNmY2ItNDM4Yy1iMzg4LWIxNGVhMjNhZjNmMCJ9.GrB2lNJKPMKM_8Ndki1ua-HWhkWXzUZ2JIjRdRtEDlps0ySdFbXq97fa1-Yfi2-ujj3N1OQqbVbB-rgAXm9UQA"'
		);
	},
	mounted() {
		this.screenWidth = document.body.clientWidth; //监听页面缩放
		this.screenHeight = document.body.clientHeight;
		window.onresize = () => {
			return (() => {
				this.screenWidth = document.body.clientWidth;
				this.screenHeight = document.body.clientHeight;
			})();
		};
	},
	methods: {
		changeList() {
			this.navshow = !this.navshow;
			// let dom = document.querySelector("#titleNav");
			// const height2 = dom.getBoundingClientRect().height;
			// this.$nextTick(() => {
			// 	let dom = document.querySelector("#titleNav");
			// 	dom.style.height = "auto";
			// 	const { height } = dom.getBoundingClientRect();
			// 	dom.style.height = height2 + "px";
			// 	dom.getBoundingClientRect();
			// 	dom.style.height = height + "px";
			// });
		},
		changeNav(index) {
			console.log(this.$route.path, index);
			this.$router.push(index);
		},
		go() {
			getJobLogListsData({
				taskId: "8bca688d782b40b6bdcd2239bf2dbeb3",
				logLevelError: true,
				logLevelWarning: true,
				offset: 1,
				pageSize: 10,
			}).then(res => {
				console.log(res);
			});
		},
	},
};
</script>

<style scoped lang="scss">
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.body {
	position: relative;
}

.big-box {
	.into-body-box-title {
		position: absolute;
		width: 100%;
		height: 70px;
		overflow: hidden;
		background-color: transparent;

		.w {
			margin: 0 auto;
			height: 70px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.logo {
				margin-top: 20px;
				width: var(--nav-logo);
				height: auto;
				svg {
					width: 150%;
					height: 100%;
				}
			}

			.phone-num {
				float: right;
				width: 186px;
				height: 29px;
				margin-top: 30px;
				display: flex;
				justify-content: space-between;
				line-height: 29px;

				.phone-iconbox {
					display: inline-block;
					width: 29px;
					height: 29px;
					padding-top: 1px;

					i {
						color: #ffffff;
						width: 100%;
						height: 100%;
						font-size: 25px;
					}
				}

				p {
					display: inline-block;
					width: 150px;
					height: 29px;
					font-size: 22px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #ffffff;
				}
			}

			.title-nav {
				.el-menu--horizontal {
					border: 0;
				}

				/deep/.el-menu-demo {
					background-color: transparent;
					height: 51px;
					display: flex;
					width: 100%;
					justify-content: space-between;

					.el-menu-item {
						height: 51px;
						line-height: 51px;
						padding: 0;
						margin: var(--nav-margin);
						font-size: var(--nav-font);
						font-family: "Microsoft YaHei";
						font-weight: 400;
						color: #fff;
						position: relative;
						&:hover {
							background-color: transparent;
						}
						&.is-active {
							border-bottom: 2px solid transparent !important;
							&::after {
								content: "";
								width: 50%;
								height: 2px;
								background: #fff;
								position: absolute;
								bottom: -2px;
								left: 25%;
								// min-width: 24px;
							}
						}
						&:last-child {
							margin-right: 0;
						}
					}
				}
			}

			.ico {
				display: none;
				.iconfont {
					font-size: 20px;
					color: #fff;
				}
			}
		}
	}
}

@media (max-width: 900px) {
	.big-box {
		.into-body-box-title {
			overflow: visible;
			.w {
				.title-nav {
					display: block;
					position: absolute;
					width: 100%;
					top: 60px;
					left: 0;
					z-index: 10;
					overflow: hidden;
					transition: all 240ms;
					.el-menu-demo {
						height: auto;
						display: block;
						.el-menu-item {
							display: flex;
							justify-content: space-between;
							align-items: center;
							float: unset;
							font-size: 16px;
							background-color: rgba(3, 36, 136, 0.7) !important;
							margin: 0;
							padding: 0 20px;

							&.is-active::after {
								display: none;
							}

							span {
								i {
									display: inline-block;
									border-top: 2px solid #fff;
									width: 12px;
									height: 12px;
									border-left: 2px solid #fff;
									transform: rotate(135deg);
								}
							}
						}
					}
				}
				.ico {
					display: block;
				}
			}
		}
	}
}
</style>
