import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

// 解决跳重复跳转到同一个地址的警告问题
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(error => error);
};

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/product",
		name: "ProductIntroduction",
		component: () => import("@/views/ProductIntroduction.vue"),
	},
	{
		path: "/cases",
		name: "SuccessfulCase",
		component: () => import("@/views/SuccessfulCase.vue"),
	},
	{
		path: "/resolution",
		name: "Solution",
		component: () => import("@/views/Solution.vue"),
	},
	{
		path: "/about_us",
		name: "AboutUs",
		component: () => import("@/views/AboutUs.vue"),
	},
	{
		path: "/join_us",
		name: "Recruitment",
		component: () => import("@/views/Recruitment.vue"),
	},
	{
		path: "/free-trial",
		name: "TryuseFirstStep",
		component: () => import("@/views/TryuseFirstStep.vue"),
	},
	{
		path: "/request_trial",
		name: "ApplyUse",
		component: () => import("@/views/ApplyUse.vue"),
	},
	{
		path: "/download",
		name: "DownloadBook",
		component: () => import("@/views/DownloadBook.vue"),
	},
	{
		path: "/details",
		name: "ProductDetails",
		component: () => import("@/views/ProductDetails.vue"),
	},

	// {
	//   path: '/about',
	//   name: 'About',
	//   // route level code-splitting
	//   // this generates a separate chunk (about.[hash].js) for this route
	//   // which is lazy-loaded when the route is visited.
	//   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
	// }
];

const router = new VueRouter({
	// mode: "hash",
	mode: "history",
	// base: process.env.VUE_APP_BASE_BUILD_NAME,
	base: "/",
	routes,
});

export default router;
