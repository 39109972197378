import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 引入ElementUI
import ElementUI from "element-ui"; // JS
import "./styles/element-variables.scss";

import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Treeselect from "@riophae/vue-treeselect";

import "@/assets/icons"; // icon
// import 'font-awesome/css/font-awesome.min.css'
import "@/assets/fonts/iconfont.css";
import "@/assets/fonts/iconfont.js";
import "@/utils/rem";

// 是否为手机端
const flag =
	navigator.userAgent.match(
		/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
	) || window.innerWidth < 900;

console.log(flag);
if (flag) {
	import("./styles/mobile.scss");
} else {
	import("./styles/pc.scss");
}

Vue.config.productionTip = false;

Vue.use(ElementUI, {
	size: "small", // set element-ui default size medium / small / mini
});

Vue.component("Treeselect", Treeselect);

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount("#app");
