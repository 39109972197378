/**
 * 封装整个项目的网络(ajax)请求
 */

//  引入
import axios from "axios";
import qs from "qs";
import local from "../utils/local";
import cookie from "../utils/cookie";
import router from "../router";

import { Notification } from "element-ui";

//设置请求地址
axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
axios.defaults.timeout = 300000; //请求拦截超时时间
axios.defaults.withCredentials = true;
//请求拦截器
axios.interceptors.request.use(config => {
	if (config.method === "get") {
		config.paramsSerializer = function (params) {
			return qs.stringify(params, {
				arrayFormat: "repeat",
			});
		};
	}
	// 取出本地token
	let tk = local.get("tk");
	if (tk) {
		config.headers.Authorization = tk; // 统一给后端携带令牌
	}
	return config;
});
//响应拦截器
axios.interceptors.response.use(
	response => {
		if (response.data != null && response.data.code !== undefined && response.data.msg !== undefined) {
			let { code, msg } = response.data;
			if (code != 200 && code != 201 && code != 503) {
				if (msg != null) {
					Notification.error({
						message: msg,
						duration: 20 * 1000,
					});
				} else {
					Notification.error({
						message: "未知错误！",
						duration: 10000,
					});
					console.log("src/utils/request.js中的响应拦截器报错");
				}
				// if (code === 401 || code === 403) {
				//     local.remove("tk")
				//     cookie.removeToken('Admin-Token')
				//     // location.hash = '/login'
				//     router.push('/Home')
				// }
			}
		}
		return response;
	},
	err => {
		if (err.response.data != null && err.response.data.code !== undefined && err.response.data.msg !== undefined) {
			let { code, msg } = err.response.data;
			// if (code === 401 || code === 403) {
			//     local.remove("tk")
			//     cookie.removeToken('Admin-Token')
			//     // if (msg != null) {
			//     //     Notification.error({
			//     //         message: msg,
			//     //         duration: 10000
			//     //     });
			//     // } else {
			//     //     Notification.error({
			//     //         message: '未知错误！',
			//     //         duration: 10000
			//     //     });
			//     // }
			//     // location.hash = '/login'
			//     router.push('/Home')

			// }
		}
	}
);

//封装
export default {
	//get
	get(url, data = {}) {
		return new Promise((resolve, reject) => {
			axios
				.get(url, {
					params: data,
				})
				.then(response => {
					resolve(response.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	},
	//post
	post(url, data = {}) {
		return new Promise((resolve, reject) => {
			axios
				.post(url, data)
				.then(response => {
					resolve(response.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	},
	//put
	put(url, data = {}) {
		return new Promise((resolve, reject) => {
			axios
				.put(url, data)
				.then(response => {
					resolve(response.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	},
	//patch
	patch(url, data = {}) {
		return new Promise((resolve, reject) => {
			axios
				.patch(url, data)
				.then(response => {
					resolve(response.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	},
	//delete
	delete(url, data = {}) {
		return new Promise((resolve, reject) => {
			axios
				.delete(url, data)
				.then(response => {
					resolve(response.data);
				})
				.catch(err => {
					reject(err);
				});
		});
	},
};
