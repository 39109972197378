/**
 * 封装对本地存储的操作
 */
import Cookies from 'js-cookie'


const TokenKey = 'Admin-Token'
export default {
    getToken() {
        return Cookies.get(TokenKey)
    },
    setToken(key, value) {
        return Cookies.set(TokenKey, value)
    },
    removeToken() {
        return Cookies.remove(TokenKey)
    }
}