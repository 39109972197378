<template>
	<div class="CopyrightBox">
		<div class="CopyrightBox-center" v-show="!isMobile">
			<div class="w">
				<div class="copytitle">
					<div class="logo">
						<svg class="icon" aria-hidden="true">
							<use xlink:href="#icon-logo2"></use>
						</svg>
					</div>
					<!-- <div class="p1">更强大的企业级数据服务平台，更多的平台适配能力，最佳的沉淀实践，一站式数据融合管理平台</div> -->
				</div>
			</div>
			<div class="border">
				<div class="ww">
					<!-- <div class="contact-way-box">
						<div class="contact-way-box-tel">
							<div class="contact-way-icon">
								<i class="iconfont icon-dianhua"></i>
							</div>
							<p>0512-68128588</p>
						</div>
						<div class="contact-way-box-company">
							<p>星河数聚科技（苏州）有限公司</p>
						</div>
					</div> -->
					<div class="contact-way-box" style="flex: 3">
						<div class="contact-way-box-email">
							<h5 style="color: #fff">更强大的企业级数据服务平台，更多的平台适配能力，更佳的沉淀实践，一站式数据融合管理平台</h5>
						</div>

						<div class="contact-way-box-http" style="margin: 0; height: 16px; line-height: 16px">
							<div style="color: #fff; font-size: 12px; display: flex; align-items: center">
								<span style="margin-right: 15px">StaRiver RDP &copy; 2024 </span>
								<a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2022044426号-1</a>
								<el-divider direction="vertical" style="margin: 0 15px"></el-divider>
								<img src="@/assets/images/bottomlogo.png" alt="" />
								<a href="https://beian.mps.gov.cn/#/query/webSearch?code=32059002005180" target="_blank">苏公网安备32059002005180号</a>
							</div>
						</div>
					</div>
					<div class="contact-way-box" style="flex: 1">
						<div class="contact-way-box-email">
							<div class="contact-way-icon">
								<i class="icon iconfont icon-youxiang2"></i>
							</div>
							<p>markting@istariver.com</p>
						</div>
						<div class="contact-way-box-http">
							<div class="contact-way-icon">
								<i class="iconfont icon-wangzhi2"></i>
							</div>
							<p>www.istariver.com</p>
						</div>
					</div>
					<!-- <div class="contact-way-box">
						<div class="contact-way-box-http">
							<div class="contact-way-icon">
								<i class="iconfont icon-dizhi2"></i>
							</div>
							<p>苏州工业园区金鸡湖大道1355号国际科技园</p>
						</div>
						<div class="contact-way-box-http">
							<div class="contact-way-icon">
								<i class="iconfont icon-dizhi2"></i>
							</div>
							<p>四川成都武侯区桂溪街道天府三街香年广场T3</p>
						</div>
					</div> -->
					<div class="qr-code" style="flex: 1">
						<div class="qr-code-2">
							<div class="qr-code-imgBox">
								<div class="qr-code-imgBox-auto">
									<img src="~@/assets/images/wechat-code.png" alt="" srcset="" />
								</div>
							</div>
							<div class="text">
								<div class="qr-code-text">扫描二维码，联系专家支持</div>
								<!-- <div class="p3">星河数聚科技（苏州）有限公司</div> -->
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- <div class="copybottom">StaRiver RDP@2021</div> -->
		</div>
		<div class="CopyrightBox-center" v-show="isMobile">
			<div class="main">
				<div class="left">
					<div class="copytitle">
						<div class="logo">
							<svg class="icon" aria-hidden="true" style="width: 120px; height: 50px">
								<use xlink:href="#icon-logo2"></use>
							</svg>
						</div>
					</div>
					<div class="contact-way-box" style="flex: 1">
						<div class="contact-way-box-email">
							<div class="contact-way-icon">
								<i class="icon iconfont icon-youxiang2"></i>
							</div>
							<p>markting@istariver.com</p>
						</div>
						<div class="contact-way-box-http">
							<div class="contact-way-icon">
								<i class="iconfont icon-wangzhi2"></i>
							</div>
							<p>www.istariver.com</p>
						</div>
					</div>
				</div>
				<div class="right">
					<div class="qr-code-imgBox">
						<div class="qr-code-imgBox-auto">
							<img v-if="!isMobile" src="~@/assets/images/wechat-code.png" alt="" srcset="" />
							<img v-else src="~@/assets/images/wechat-code-mobile.png" alt="" srcset="" />
						</div>
					</div>
					<div class="text">
						<div class="qr-code-text">扫描二维码，联系专家支持</div>
						<!-- <div class="p3">星河数聚科技（苏州）有限公司</div> -->
					</div>
				</div>
			</div>
			<div class="bottom">
				<span style="margin-right: 15px">StaRiver RDP &copy; 2024 </span>
				<div class="link">
					<a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2022044426号-1</a>
					<el-divider direction="vertical"></el-divider>
					<img src="@/assets/images/bottomlogo.png" alt="" />
					<a href="https://beian.mps.gov.cn/#/query/webSearch?code=32059002005180" target="_blank">苏公网安备32059002005180号</a>
				</div>
			</div>

			<!-- <div class="copybottom">StaRiver RDP@2021</div> -->
		</div>
	</div>
</template>

<script>
export default {
	components: {},
	props: {},
	data() {
		return {
			screenWidth: "",
			screenHeight: "",
		};
	},
	computed: {
		isMobile() {
			return (
				navigator.userAgent.match(
					/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
				) || this.screenWidth < 900
			);
		},
	},
	watch: {},
	created() {},
	mounted() {
		this.screenWidth = document.body.clientWidth; //监听页面缩放
		this.screenHeight = document.body.clientHeight;
		window.onresize = () => {
			return (() => {
				this.screenWidth = document.body.clientWidth;
				this.screenHeight = document.body.clientHeight;
			})();
		};
	},
	methods: {},
};
</script>

<style scoped lang="scss">
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
.CopyrightBox {
	margin: 0 auto;
	overflow: hidden;
	width: 100%;
	.CopyrightBox-center {
		background-color: #000e24;
	}
	.CopyrightBox-center {
		// height: 281px;
		position: relative;
		padding: 40px 0;
		.w {
			width: var(--container-width);
			margin: 0 auto;
			overflow: hidden;
			.copytitle {
				height: 38px;
				display: flex;
				justify-content: space-between;
				align-items: center;
				.logo {
					width: 80px;
					height: 36px;
					svg {
						width: 150%;
						height: 150%;
					}
				}
				.p1 {
					font-size: 18px;
					font-weight: 400;
					color: #fff;
					font-family: "DFPLiJinHeiW8-GB";
					line-height: 36px;
				}
			}
		}
		.border {
			// border-top: 1px solid rgba(255, 255, 255, 0.15);
			// border-bottom: 1px solid rgba(255, 255, 255, 0.15);
			.ww {
				width: var(--container-width);
				margin: 0 auto;
				overflow: hidden;
				display: flex;
				// justify-content: space-between;
				.contact-way-box {
					// width: 520px;
					flex: 1;
					// height: 160px;
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					margin-top: 24px;

					&:nth-child(2) {
						.contact-way-box-email,
						.contact-way-box-http {
							padding-left: 0px;
						}
					}

					&:last-child {
						.contact-way-box-http {
							padding-left: 45px;
						}
					}

					.contact-way-box-company {
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: 400;
						color: #ffffff;
					}
					.contact-way-box-tel,
					.contact-way-box-email,
					.contact-way-box-http {
						height: 25px;
						display: flex;
						line-height: 25px;
						margin-bottom: 10px;
						.contact-way-icon {
							width: 16px;
							height: 16px;
							text-align: center;
							line-height: 25px;
							.iconfont {
								font-size: 16px;
								color: #fff;
								&.icon-youxiang2 {
									font-size: 14px;
								}
							}
						}
						p {
							color: #fff;
							font-size: 20px;
							margin-left: 15px;
						}
					}
					.contact-way-box-email,
					.contact-way-box-http {
						p {
							color: #fff;
							font-size: 14px;
							margin-left: 15px;
						}
						img {
							margin-right: 5px;
							width: 15px;
							height: 15px;
						}
						a {
							text-decoration: none;
							color: #749ae3;
						}
						.el-divider--vertical {
							margin: 0 10px;
						}
					}
				}
				.qr-code {
					// flex: 1;
					.qr-code-2 {
						// height: 125px;
						display: flex;
						align-items: center;
						justify-content: center;
						flex-direction: column;

						.text {
							text-align: right;
							// margin-right: 20px;
							.qr-code-text {
								height: 25px;
								font-size: 14px;
								line-height: 25px;
								color: #ffffff;
							}
							.p3 {
								margin-top: 10px;
								height: 25px;
								font-size: 12px;
								line-height: 25px;
								color: #ffffff;
							}
						}

						.qr-code-imgBox {
							width: 80px;
							height: 80px;
							background-color: #fff;
							overflow: hidden;
							.qr-code-imgBox-auto {
								width: 72px;
								height: 72px;
								margin: 4px;
								img {
									width: 100%;
									height: 100%;
								}
							}
						}
					}
				}
			}
		}
		.copybottom {
			height: 40px;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			line-height: 40px;
			text-align: center;
			font-size: 12px;
			font-family: "Microsoft YaHei";
			font-weight: 400;
			color: #fff;
		}
	}
}

@media (max-width: 900px) {
	.CopyrightBox {
		.CopyrightBox-center {
			padding: 40px 10px 0;
		}
	}

	.main {
		display: flex;
		color: #fff;
		justify-content: space-between;
		.left {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			.contact-way-box {
				margin-top: 10px;
				.contact-way-box-email,
				.contact-way-box-http {
					display: flex;
					align-items: center;
					margin-bottom: 0px;
					margin-top: 10px;
					.contact-way-icon {
						text-align: center;
						.iconfont {
							font-size: 14px;
							&.icon-youxiang2 {
								font-size: 12px;
							}
						}
					}
					p {
						font-size: 12px;
						margin-left: 10px;
					}
				}
			}
		}
		.right {
			display: flex;
			flex-direction: column;
			align-items: center;
			.qr-code-imgBox {
				width: 98px;
				height: 98px;
				background-color: #fff;
				overflow: hidden;
				.qr-code-imgBox-auto {
					img {
						width: 100%;
						height: 100%;
					}
				}
			}
			.text {
				font-size: 9px;
				margin-top: 5px;
			}
		}
	}
	.bottom {
		border-top: 1px solid rgba(255, 255, 255, 0.2);
		padding: 10px 0 20px;
		margin-top: 20px;
		color: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		.link {
			display: flex;
			align-items: center;
			margin-top: 5px;
			img {
				margin-right: 5px;
				width: 15px;
				height: 15px;
			}
			a {
				text-decoration: none;
				color: #749ae3;
				font-size: 12px;
			}
			.el-divider--vertical {
				margin: 0 5px;
				background-color: #749ae3;
			}
		}
	}
}
</style>
